<template>
    <v-main>
      <div class="main-content">
        <role-tabs />
        <div class="main-content__container _border-top-transparent">
          <!-- <Message class="my-1" v-if="isBlockedManageRoles" :closable="false" severity="info"><div v-html="$t('alert_add_role')"></div></Message> -->
    <DataTable 
              :value="roles" 
              :rows="10" 
              :loading="loadingFlag"
        :scrollable="true" 
        showGridlines 
        responsiveLayout="scroll"
        stripedRows
        class="p-datatable-sm">
        <!-- table-nostretched -->
    
        
        <Column field="id" header="ID" :showFilterMenu="false" :styles="{ 'max-width': '5rem' }">
          <template #body="{ data }">
            <div>
              {{ data.id }}
            </div>
          </template>
        </Column>
        <Column field="name" :header="$t('role')" :showFilterMenu="false" :styles="{ 'max-width': '20rem' }">
          <template #body="{ data }">
            <div>
              {{ setRolesName(data.name) }}
            </div>
          </template>
        </Column>
        <Column field="created_at" :header="$t('date')" :showFilterMenu="false">
          <template #body="{ data }">
            <div>
              {{ new Date(data.created_at).toLocaleString() }}
            </div>
          </template>
        </Column>
        <!-- <Column field="ui_permissions" :header="$t('permissions')" :showFilterMenu="false" :styles="{ 'max-width': '20rem' }">
          <template #body="{ data }">
           <v-row>
              <v-col>
                <span class="ml-1" v-for="(permission, index) in data.ui_permissions" :key="index">
                  <v-chip 
                    color="var(--green-success)"
                    style="color: var(--white)"
                    label
                    small
                    class="text-capitalize"
                  >
                    {{ permission }}
                  </v-chip> 
                </span>
              </v-col>
           </v-row>
          </template>
        </Column> -->
        <!-- <Column field="is_active" :header="`${$t('off')}/${$t('on')}`" :showFilterMenu="false" :styles="{ 'max-width': '10rem' }">
          <template #body="{ data }">
            <v-switch
            v-model="data.is_active"
            hide-details
            color="green accent-4" inset
            class="banners__active mt-0 custom-switch "
            :loading="data.isActive_loading"
            @change="toggleRole(data)"
            ></v-switch>
          </template>
        </Column> -->
        <!-- <Column  :exportable="false" :styles="{ 'min-width': '10rem' }">
          <template #body="{ data }">
            <div v-if="!isBlockedManageRoles" class="d-flex align-center flex-wrap action-buttons">
              <Button icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-warning mr-2" @click="toEditRole(data)" />
              <Button icon="pi pi-trash" class="p-button-sm p-button-rounded p-button-danger" @click="toDeleteRole(data)" />
            </div>
          </template>
        </Column> -->
        </DataTable>
        </div>
       
        <!-- Create Role -->
        <v-dialog
          v-model="toCreateDialog"
          max-width="550px"
          scrollable
          :persistent="formLoading"
         
          content-class="br-16"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            <span>{{ $i18n.t('add_role') }}</span>
          </v-card-title>
          <v-card-text class="pb-0 pt-2">
            <v-form
                ref="roleForm"
                v-model="isValidRole"
            >
            
            <v-select
              hide-details="auto"
              class="auto"
              v-model="createRole.role"
              :items="title_role"
              item-text="text"
              item-value="value"
              required
              :rules="[v => !!v || $t('required_field')]"
              :menu-props="{contentClass: 'main-list'}"
              outlined 
              dense 
              color="var(--main-flamingo)"
              :label="$i18n.t('title')"
          >
          </v-select>
          <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
              v-model="isAllPermissions"
                :label="this.$t('all_permissions')"></v-checkbox>
          <v-select
                  hide-details="auto"
                  class="auto"
                  :rules="[v => !!v || $t('required_field')]"
                  required
                  :menu-props="{contentClass: 'main-list'}"
                  :disabled="isAllPermissions"
                  v-model="createRole.ui_permissions"
                  :items="Object.values(test_ui_permissions)"
                  multiple
                  :label="this.$t('permissions')"
                  outlined
                  dense  
                  color="var(--main-flamingo)"
              >
            </v-select>
            <v-switch
            v-model="createRole.is_active"
            hide-details
            :label="this.$t('active')"
            color="green accent-4" inset
            class="mt-0 ml-1 mb-3 custom-switch "
            ></v-switch>
        </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col style="text-align: right" class="pa-0">
              <v-btn style="text-transform: none" class="success-btn" 
              :disabled="!isValidRole"
              :loading="formLoading" @click="confirmCreateRole">
                {{ $i18n.t('save') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <!-- Edit Role -->
        <!-- <v-dialog
          v-model="toEditDialog"
          max-width="550px"
          scrollable
          :persistent="formLoading"
         
          content-class="br-16"
      >
        <v-card>
          <v-card-title class="font-weight-bold">
            <span>{{ $i18n.t('role_edit') }}</span>
          </v-card-title>
          <v-card-text class="pb-0 pt-2">
            <v-form
                ref="roleForm"
            >
            
            <v-select
              hide-details="auto"
              class="auto"
              v-model="editRole.role"
              :items="title_role"
              item-text="text"
              item-value="value"
              :rules="[v => !!v || $t('required_field')]"
              :menu-props="{contentClass: 'main-list'}"
              outlined 
              dense 
              color="var(--main-flamingo)"
              :label="$i18n.t('title')"
          >
          </v-select>
          <v-checkbox color="var(--main-flamingo)" class="mt-0 mb-2" hide-details 
              v-model="isEditAllPermissions"
                :label="this.$t('all_permissions')"></v-checkbox>
          <v-select
                  hide-details="auto"
                  class="auto"
                  :disabled="isEditAllPermissions"
                  :rules="[v => !!v || $t('required_field')]"
                  required
                  :menu-props="{contentClass: 'main-list'}"
                  v-model="editRole.ui_permissions"
                  :items="test_ui_permissions"
                  multiple
                  :label="this.$t('permissions')"
                  outlined
                  dense  
                  color="var(--main-flamingo)"
              >
            </v-select>
            <v-switch
                v-model="editRole.is_active"
                hide-details
                :label="this.$t('active')"
                color="green accent-4" inset
                class="mt-0 ml-1 mb-3 custom-switch "
                ></v-switch>
        </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col style="text-align: right" class="pa-0">
              <v-btn style="text-transform: none" class="success-btn" dark :loading="formLoading" @click="confirmEditRole">
                {{ $i18n.t('save') }}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog> -->
      <!-- Delete Window -->
      <!-- <v-dialog
      v-model="toDeleteDialog"
      max-width="550px"
      scrollable
      :persistent="formLoading"
     
      content-class="br-16"
  >
    <v-card>
      <v-card-title class="font-weight-bold flex-column align-start">
        <span>{{ $i18n.t('role_delete') }}</span>
        <span class="c-red">{{ $i18n.t('role_delete_confirm') }}</span>
      </v-card-title>
      <v-card-text class="pb-0 pt-2">
        <v-text-field
          v-model="deleteRole.role"
          disabled
          outlined 
          dense 
          color="var(--main-flamingo)"
          :label="$i18n.t('title')"
      >
      </v-text-field>
      <v-select
              disabled
              :menu-props="{contentClass: 'main-list'}"
              v-model="deleteRole.ui_permissions"
              :label="this.$t('permissions')"
              outlined
              dense  
              color="var(--main-flamingo)"
          >
        </v-select>
        <v-switch
          disabled
                v-model="deleteRole.is_active"
                hide-details
                :label="this.$t('active')"
                color="green accent-4" inset
                class="mt-0 ml-1 mb-3 custom-switch "
                ></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-col style="text-align: right" class="pa-0">
          <v-btn style="text-transform: none" class="main-btn" dark :loading="formLoading" @click="confirmDeleteRole">
            <v-icon>delete</v-icon>
                {{ $i18n.t('delete') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog> -->
</div>
    </v-main>
</template>

<script>
import RoleTabs from './RoleTabs.vue';
import { mapGetters } from 'vuex';
    export default {
        name: 'RolesSettings',
        components:{
          RoleTabs,
        },
        data() {
            return {
              editRole:{

              },
              deleteRole:{

              },
              createRole:{
                role: '',
                ui_permissions: [],
                is_active: false,
              },
              blockedAllPermissions: false,
              isAllPermissions: false,
              isEditAllPermissions: false,
              isActiveLoadingFlag: false,
              isValidRole: true,
              toCreateDialog: false,
              toEditDialog: false,
              toDeleteDialog: false,
              formLoading: false,
              loadingFlag: true,
                fields: [
                    {value: 'role', text: this.$t('role'), sortable: true},
                    {value: 'ui_permissions', text: this.$t('permissions'), sortable: true},
                    {value: 'is_active', text: this.$t('off') + '/' + this.$t('on'), sortable: true},
                    {value: 'actions', text: this.$t('actions'), sortable: true},
                ],
                filters: {
                    is_active: '',
                    user_id: '',
                    username: '',
                    email: '',
                    name: '',
                    tel: '',
                },
                testModel: [],
                test_ui_permissions: [
                  // {
                  //   text: this.$t('all'),
                  //   value: 'All',
                  // },
                  {
                    text: this.$i18n.t('multiacc_setup'),
                    value: 'MultiSetup',
                  },
                  {
                    text: this.$i18n.t('geo_restrictions'),
                    value: 'Geo',
                  },
                  {
                    text: `CMS - ${this.$i18n.t('theme_logo')}`,
                    value: 'Themes',
                  },
                  {
                    text: `CMS - ${this.$i18n.t('banners')}`,
                    value: 'Banners',
                  },
                  {
                    text: `CMS - ${this.$i18n.t('local')}`,
                    value: 'Localizations',
                  },
                  {
                    text: `CMS - ${this.$i18n.t('custom_js')}`,
                    value: 'CustomJs',
                  },
                  {
                    text: `CMS - ${this.$i18n.t('players_ava')}`,
                    value: 'PlayersAva',
                  },
                  {
                    text: `${this.$i18n.t('players')}`,
                    value: 'Players',
                  },
                  {
                    text: this.$i18n.t('players_authorization_logs'),
                    value: 'AllAuthLogs',
                  },
                  {
                    text: this.$i18n.t('games'),
                    value: 'Games',
                  },
                  {
                    text: this.$i18n.t('game_fee_settings'),
                    value: 'GameFee',
                  },
                  {
                    text: this.$i18n.t('game_settings'),
                    value: 'GameSettings',
                  },
                  {
                    text: this.$i18n.t('cash_flow'),
                    value: 'Cashflow',
                  },
                  {
                    text: this.$i18n.t('financial_transactions'),
                    value: 'FinTransactions',
                  },
                  {
                    text: this.$i18n.t('pay_methods'),
                    value: 'PayMethods',
                  },
                  {
                    text: this.$i18n.t('dep_bonuses'),
                    value: 'DepBonuses',
                  },
                  {
                    text: this.$i18n.t('free_rounds'),
                    value: 'FreeRounds',
                  },
                  {
                    text: this.$i18n.t('player_levels'),
                    value: 'PlayerLevels',
                  },
                  {
                    text: this.$i18n.t('promo'),
                    value: 'Promocodes',
                  },
                  {
                    text: this.$i18n.t('manage_users'),
                    value: 'ManageUsers',
                  },
                  {
                    text: this.$i18n.t('roles_settings'),
                    value: 'RoleSettings',
                  },
                  {
                    text: this.$i18n.t('reports'),
                    value: 'Reports',
                  },
                  {
                    text: this.$i18n.t('admin_auth'),
                    value: 'AdminLogs',
                  },
                ],
                title_role: [
                  {
                    text: 'Admin',
                    value: 'ADMIN',
                  },
                  {
                    text: 'Promo manager',
                    value: 'PROMO_MANAGER',
                  },
                  {
                    text: 'Financial manager',
                    value: 'FINANCIAL_MANAGER',
                  },
                  {
                    text: 'Game manager',
                    value: 'GAME_MANAGER',
                  },
                  {
                    text: 'Product manager',
                    value: 'PRODUCT_MANAGER',
                  },
                  {
                    text: 'CFO',
                    value: 'CFO',
                  },
                  {
                    text: 'CRM manager',
                    value: 'CRM_MANAGER',
                  },
                  ],
                page: 1,
                size: 25,
                pageOptions: [10, 25, 100],
                activeFilters:{},
                filterPages: 25,
            }
        },
        beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'roles_settings')
    });
    },
    watch: {
      editRole(newValue){
        if (newValue.ui_permissions.includes('All')) {
          this.isAllPermissions = true;
        }
      },
      isAllPermissions(newValue){
        if(newValue){
          this.createRole.ui_permissions = [];
          this.createRole.ui_permissions.push('All');
        } else{
          this.createRole.ui_permissions = [];
          }
      },
      isEditAllPermissions(newValue){
        console.log(newValue);
        if (newValue) {
          this.editRole.ui_permissions = [];
          this.editRole.ui_permissions.push('All');
        } else{
          this.editRole.ui_permissions = [];

        }
      },
    },
        async mounted(){
          if (this.roles.length === 0) {
              await this.$store.dispatch('roles/awaitGetRoles');
            }
            this.activeFilters = {};
            this.loadingFlag = false;
        },
        computed: {
             ...mapGetters({
                roles: 'roles/getRole',
                tokenUser: 'tokenUser'
             }),
            //  isBlockedManageRoles(){
            //   const perms = this.tokenUser;
            //   if (!perms.includes("RoleSettings") && !perms.includes("All")) {
            //     return true;
            //   } else{
            //     return false;
            //   }
            //  },   
            },
        methods:{
          setRolesName(role){
            switch(role){
              case 'SUPERUSER':
              return 'Super user';
              case 'ADMIN':
              return 'Admin';
              case 'PROMO_MANAGER':
              return 'Promo manager';
              case 'FINANCIAL_MANAGER':
              return 'Financial manager';
              case 'GAME_MANAGER':
              return 'Game manager';
              case 'PRODUCT_MANAGER':
              return 'Product manager';
              case 'CFO':
              return 'CFO';
              case 'CRM_MANAGER':
              return 'CRM manager';
            }
          },
    //       async toggleRole(role){
    //         role.isActive_loading = true;
    //         this.isActiveLoadingFlag = true;
    //     const data = {
    //       id: role.id,
    //       ui_permissions: role.ui_permissions,
    //       role: role.role,
    //       is_active: role.is_active,
    //     }
    //     const res = await this.$store.dispatch('roles/awaitUpdateRole', data);
    //     if (res) {
    //   this.$toast.add({ severity: 'success', summary: this.$t('role_updated'), life: 4000 })
    // } else{
    //   this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
    //     }
    //     await this.$store.dispatch('roles/awaitGetRoles');
    //     role.isActive_loading = false;
    //     this.isActiveLoadingFlag = false;
    //   },
      async confirmCreateRole(){
        const create = this.createRole;
        this.formLoading = true;
        const data = {
          id: create.id,
          ui_permissions: create.ui_permissions,
          role: create.role,
          is_active: create.is_active,
        }
        const res = await this.$store.dispatch('roles/awaitCreateRole', data);
        if (res) {
          this.toCreateDialog = false;
          
          this.createRole = {
                role: '',
                ui_permissions: [],
                is_active: false,
                  };
              
          this.$toast.add({ severity: 'success', summary: this.$t('role_created'), life: 4000 })
          
          this.$refs.roleForm.resetValidation();
        } else{
      this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
        }
        await this.$store.dispatch('roles/awaitGetRoles');
        this.formLoading = false;
      },
      // async confirmEditRole(){
      //   this.formLoading = true;
      //   const edit = this.editRole;
      //   const data = {
      //     id: edit.id,
      //     ui_permissions: edit.ui_permissions,
      //     role: edit.role,
      //     is_active: edit.is_active,
      //   }
      //   const res = await this.$store.dispatch('roles/awaitUpdateRole', data);
      //   if (res) {
      //     this.toEditDialog = false;
      // this.$toast.add({ severity: 'success', summary: this.$t('role_updated'), life: 4000 })
      //   this.$refs.roleForm.resetValidation();
      //   } else{
      // this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
      //   }
      //   this.formLoading = false;
      // },
      // async confirmDeleteRole(){
      //   this.formLoading = true;
        
      //   const res = await this.$store.dispatch('roles/awaitDeleteRole', this.deleteRole.id);
      //   if (res) {
      //     this.toDeleteDialog = false;
      // this.$toast.add({ severity: 'success', summary: this.$t('role_deleted'), life: 4000 })
      // await this.$store.dispatch('roles/awaitGetRoles');
      //   } else{
      // this.$toast.add({ severity: 'error', summary: this.$t('just_error'), life: 4000 })
      //   }
      //   this.formLoading = false;
      // },
      toCreateRole(){
        this.toCreateDialog = true;
        this.isAllPermissions = false;
      },
            // toEditRole(role){
            //     this.isAllPermissions = false;
            //     const toEdit = role;
            //     this.editRole = toEdit;
            //     this.toEditDialog = true;
            // },
            // toDeleteRole(role){
            //   this.toDeleteDialog = true;
            //   this.deleteRole = role;
            // },
            roleText(status) {
            switch (status) {
              case 0:
                return this.$i18n.t('processing');
              case 1:
                return this.$i18n.t('completed');
              case 2:
                return this.$i18n.t('completed_with_fee');
              case 3:
                return  this.$i18n.t('canceled');
            }
          },
    roleLabelColor(status) {
      switch (status) {
        case 0:
          return 'var(--yellow-success)';
        case 1:
          return 'var(--green-success)';
        case 2:
          return 'var(--yellow-success)';
        case 3:
          return  'var(--red)';
          default: 
          return 'var(--main-flamingo)'
      }
    },
        },
    }
</script>

<style lang="scss" scoped>
.action-buttons {
    gap: 5px;
		&__button {

            min-width: 30px!important;
            height: 30px!important;
		}
}


</style>